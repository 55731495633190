



































































































































































import AppApiMixin from "@/components/mixins/AppApi.vue";
import {Component, Mixins} from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import XNotice from "@/components/hoc/SimpleNotice.vue";
import CustomForm from "@/components/CustomForm.vue";
import SimpleButton from "@/components/SimpleButton.vue";
import {mapGetters, mapState} from "vuex";
import {
  AppealCustomFields,
  AppealThemes,
  AppealThemeTypes
} from "@/models/appeals";
import {AccountingPoint} from "@/models/accounting-point";
import {appealsAcceptedExtensions} from "@/assets/scripts/utils";
import {FieldTypes} from "@/assets/scripts/custom-fields/field-types"

@Component({
  components: {
    Page,
    XNotice,
    CustomForm,
    SimpleButton,
  },
  computed: {
    ...mapGetters("contract", {
      contractId: "id",
    }),
    ...mapGetters("user", {
      userEmail: "email",
    }),
    ...mapGetters("accountingPoints", {
      accountingPoints: "allPoints",
    }),
    ...mapGetters("appeals", {
      themeName: "themeName",
      themeId: "themeId",
      themeTypes: "themeTypes",
      themeTypesId: "themeTypesId",
      appealCustomFields: "appealCustomFields",
    }),
    ...mapState({ accountingPoint: "accountingPoint" }),
    ...mapGetters({
      sortedPointsByObject: "accountingPoints/sortedPointsByObject",
    }),
  },
  data: () => ({
    requiredRule: [
      v => !!v || 'Заполните данное поле',
    ],
  }),
})
class AppealsAdd extends Mixins(AppApiMixin) {
  appealThemesArr: {[x: string]: number} = {};
  appealThemeTypesArr: {[x: string]: number} = {};
  accountingPointsArr: {[x: string]: number} = {};
  accountingPointsObjectsArr: {[x: string]: number} = {};
  customFields = [];

  values: {[x: string]: any} = {};
  isFormValid = false;

  // флаги тем
  isReturnTheme = false;
  isChangeTheme = false;
  isCloseTheme = false;

  file: Array<File> | [];
  submitSuccessText = "";
  submitErrorText = "";
  useSuccessAlert = false;
  useSubmitButtonLoading = false;

  noAPointsForSelect = 'Точки учета отсутствуют'

  file_count = 0;
  min = null;
  max = null;

  mounted() {
    this.getAllAppealThemes(this.contractId);
    this.values['email'] = this.userEmail;
  }

  /**
   * ДАННЫЕ
   */

  /**
   * Список тем обращений
   */
  public get appealThemeItems() {
    const themes = this.themeName ? this.themeName.map((name: AppealThemes) => name.название) : [];
    const appealThemesArr_ids = this.themeName ? this.themeName.map((name: AppealThemes) => name.$номерЗаписи) : [];

    themes.forEach((item, index) => {
      this.appealThemesArr[item] = appealThemesArr_ids[index];
    });
    return [...themes]
  }

  /**
   * Список вопросов из темы обращения
   */
  public get appealTypeItems() {
    const themeTypes = this.themeTypes ? this.themeTypes.map((name: AppealThemeTypes) => name.название) : [];
    const themeTypes_mas_ids = this.themeTypes ? this.themeTypes.map((name: AppealThemeTypes) => name.$номерЗаписи) : [];

    themeTypes.forEach((item:number, index:number) => {
      this.appealThemeTypesArr[item] = themeTypes_mas_ids[index];
    });

    return [...themeTypes];
  }

  /**
   * Список точек учета
   */
  public get accountingPointsForSelect() {
    const accountingPointsArr = this.accountingPoints ? this.accountingPoints.map((name: AccountingPoint) => name.названиету) : [];
    const accountingPointsArr_ids = this.accountingPoints ? this.accountingPoints.map((name: AccountingPoint) => name.лицевой) : [];
    const accountingPointsObjectsArr_ids = this.accountingPoints ? this.accountingPoints.map((name: AccountingPoint) => name.объект) : [];

    accountingPointsArr.forEach((item, index) => {
      this.accountingPointsArr[item] = accountingPointsArr_ids[index];
      this.accountingPointsObjectsArr[item] = accountingPointsObjectsArr_ids[index];
    });

    if (accountingPointsArr.length > 0) {
      return [...accountingPointsArr];
    } else {
      return [this.noAPointsForSelect];
    }
  }

  /**
   * Доступные расширения для файлов
   */
  getAppealsAcceptedExtensions() { return appealsAcceptedExtensions() }
  getAppealsAcceptedExtensionsText() { return 'Доступны форматы: ' + appealsAcceptedExtensions() }

  /**
   * СОБЫТИЯ
   */

  /**
   * Изменение выбранного вида
   */
  onSelectTheme() {
    this.isReturnTheme = (this.values['appealTheme'] === "Возобновление подачи электрической энергии");
    this.isChangeTheme = (this.values['appealTheme'] === "Установление и изменение зон суток в соответствии с дифференциацией тарифов (тарифные зоны)");
    this.isCloseTheme = (this.values['appealTheme'] === "Полное и (или) частичное ограничение режима потребления электрической энергии");
    if(this.values['appealTheme'])
      this.getCustomFieldsData({themeId: this.appealThemesArr[this.values['appealTheme']],
          typeId: this.appealThemeTypesArr[this.values['appealType']] ?? null,
          contractId: this.contractId },
        () => {});
    this.customFields = this.appealCustomFields;
    this.customFields.forEach((item, i) => {
      this.values['customFields[][' +item['id'] + ']'] = item;
    });
  }

  /**
   * Изменение выбранной темы
   */
  onSelectType() {
    this.getCustomFieldsData({themeId: this.appealThemesArr[this.values['appealTheme']],
        typeId: this.appealThemeTypesArr[this.values['appealType']] ?? null,
        contractId: this.contractId },
        () => {});
    this.customFields = this.appealCustomFields;
    this.customFields.forEach((item, i) => {
      this.values['customFields[][' +item['id'] + ']'] = item;
    });
  }

  /**
   * Изменение поля файлов
   */
  onFileChanged(files: Array<File>) {
    this.file = files;
  }

  /**
   * Отправка обращения
   */
  async submitForm() {
    this.useSuccessAlert = false
    this.useSubmitButtonLoading = true
    const formData = new FormData

    for (let key in this.values) {
      switch (key) {
        case "appealTheme":
          if (this.values[key] != "" && this.appealThemesArr)
          formData.append(key, this.appealThemesArr[this.values[key]].toString());
          break;
        case "appealType":
          if (this.values[key] != "" && this.appealThemeTypesArr) {
            formData.append(key, this.appealThemeTypesArr[this.values[key]].toString());
          }
          break;
        case "accountingPoint":
          if (this.values[key] != "" && this.accountingPointsArr) {
            formData.append(key, this.accountingPointsArr[this.values[key]].toString());
          }
          break;
        case "file":
          if (this.file) {
            for (let file of this.file) {
              formData.append(key + this.file_count, file);
              this.file_count++;
            }
          }
          break;
        default:
          if (this.requiredField(key) && key.indexOf('customFields') < 0) {
            formData.append(key, this.values[key]);
          }
          break;
      }
    }

    if (this.customFields.length > 0) {
      for (let key in this.customFields) {
        let item = [];

        item['параметр'] = this.customFields[key]['id'];
        formData.append('appealCustom['+ key + '][параметр]', item['параметр']);

        if(this.customFields[key]['значение'])
          item['значение'] = this.customFields[key]['значение'].length > 0
            ? this.customFields[key]['значение']
            : this.customFields[key]['поУмолчанию'];
        else
          item['значение'] = this.customFields[key]['поУмолчанию'];

        if(this.customFields[key]['тип'] === FieldTypes.RANGE && item['значение']) {
          formData.append('appealCustom['+ key + '][значение]',
            item['значение'][0].split('T')[0].split('-').reverse().join('.')
            + '-' + item['значение'][1].split('T')[0].split('-').reverse().join('.'));
        }

        else if(this.customFields[key]['тип'] === FieldTypes.MONTH && item['значение']) {
          let date = item['значение'].split('T')[0].split('-');
          formData.append('appealCustom['+ key + '][значение]',date[1] + '.' + date[0]);
        }

        else if(this.customFields[key]['тип'] === FieldTypes.DATE && item['значение']) {
          let date = item['значение'].split('T')[0].split('-');
          formData.append('appealCustom['+ key + '][значение]',date[2] + '.' + date[1] + '.' + date[0]);
        }

        else if(this.customFields[key]['тип'] === FieldTypes.FILE && item['значение']) {
          formData.append('appealCustom['+ key + '][значение]', item['значение'][0] ?? '');
          formData.append('appealCustom['+ key + '][идФайл]','');
        }

        else if(this.customFields[key]['тип'] === FieldTypes.COMBOBOX && item['значение']) {
          formData.append('appealCustom['+ key + '][свойвариант]', this.customFields[key]['свойвариант'] ?? '');
          let bitMask = 0;
          item['значение'].split(';').forEach((el, i) => {
            if(this.customFields[key]['возможныеЗначения'].split(';').includes(el)) {
              bitMask += Math.pow(2, i);
            }
          });
          formData.append('appealCustom['+ key + '][значение]', bitMask.toString());
        }

        else {
          formData.append('appealCustom['+ key + '][значение]', item['значение']);
        }
      }
    }

    formData.append('contractId', this.contractId);
    formData.append('file_count', String(this.file_count));

    try {
      const data = await this.sendAppeal(formData)

      if(data && data.result && (data.appealId) != 0) {
        this.submitSuccessText = 'Обращение успешно отправлено';

        this.useSuccessAlert = true;

        setTimeout(() => (this.$router.push({ name: "appealsByContract", params: { contractId: this.contractId } })), 2000);
      }

    } catch (e) {
      this.submitErrorText = e.data;
    }
    this.submitSuccessText = '';
    this.useSubmitButtonLoading = false
  }

  /**
   * ФИЛЬТРЫ
   */

  /**
   * Фильтр для отображения поля
   * @param fieldName
   */
  showField(fieldName) {
    return this.requiredField(fieldName);
  }

  /**
   * Фильтр для полей, обязательных к заполнению
   * @param fieldName
   */
  requiredField(fieldName) {
    switch (fieldName) {
      case "message": return (!this.isReturnTheme && !this.isChangeTheme && !this.isCloseTheme);
      case "file": return (!this.isReturnTheme && !this.isChangeTheme && !this.isCloseTheme);
      case "pu": return this.isReturnTheme || this.isChangeTheme || this.isCloseTheme;
    }
    return true;
  }

  /**
   * Валидация в зависимости от типа заявки
   * @param fieldName
   */
  customRule(fieldName) {
    if (fieldName == "file") {
      return [];
    }
    if (this.requiredField(fieldName)) {
      if (fieldName == "email") {
        return [
          v => !!v || 'Заполните данное поле',
          v => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Неправильный формат",
          // v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(v) || "Неправильный формат",
        ]
      }
      if (fieldName == "phone") {
        return [
          v => !!v || 'Заполните данное поле',
          (v) => {
            const endRegExp = "([- ]?\\d{2}){2}$";
            const phonePatterns = [
              new RegExp("^(\\d{4}|\\(\\d{4}\\))[- ]?\\d{2}" + endRegExp),
              new RegExp("^(\\d{3}|\\(\\d{3}\\))[- ]?\\d{3}" + endRegExp)
            ];
            return phonePatterns.some(pattern => pattern.test(v)) || "Неправильный формат";
          }
        ]
      }
      return [
        v => !!v || 'Заполните данное поле',
      ];
    }
    return [];
  }
  invalidForm() {
    return false;
  }

  // private backToAppealsPage(): void {
  //   this.redirectToPage("appealsByContract");
  // }
}

export default AppealsAdd

